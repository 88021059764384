.terms__top {
  margin-bottom: 30px;
}

.formWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 30px;

  padding-bottom: 20px;

  .formContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;

    .input {
      max-width: 654px;
    }

    textarea {
      width: 100%;
      height: 125px;
      padding: 15px 25px;


      /* UI Properties */
      border: none;
      background: #EEEEEE 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
    }

    textarea:focus {
      outline: none;
    }

  }

  .btnWrapper {

    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    flex-direction: row;

    gap: 20px;
  }
}
.btnWrapper__bottom {
  margin-top: 60px;

  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  flex-direction: row;

  gap: 20px;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 120px;
  height: 120px;
}