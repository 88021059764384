.layoutContainerNavbar {
  flex-basis: 100%;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  max-width: 100%;

  background-color: #eee;

  .layoutContainer {
    width: 100%;
/*    display: flex;
    flex: 1 1 auto;
    align-items: stretch;*/

    min-height: 100vh;
  }

  .layoutWrapper {
    width: 100%;

    display: flex;
    flex-basis: 100%;
    flex-direction: row;

    .layoutPage {
      width: 100%;
      height: 100%;

      margin: 40px 40px 40px 150px;

    }
  }
}
