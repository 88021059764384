.searchParent {
  position: relative;

  .search {
    width: 283px;
    padding: 0 20px 0 55px;
  }

  .svgMagnifier {
    position: absolute;
    left: 16px;
    top: 11px;
  }
}