.faq__top {
  padding: 0 32px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .selectSection {
    display: flex;
    gap: 30px;
  }

  button {
    width: 111px;
    height: 47px;
    /* UI Properties */
    background: #d1002a 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    border: none;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &:hover {
      -webkit-box-shadow: 3px 3px 10px 6px #dddddd;
      -moz-box-shadow: 3px 3px 10px 6px #dddddd;
      box-shadow: 3px 3px 10px 6px #dddddd;
    }
  }
}

.cellWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.ellipsisWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
}

.svgTrashBin {
  width: 30px;
  color: #ca2d2d;

  cursor: pointer
}
