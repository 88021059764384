.selectParent{
  position: relative;

  .select {
    padding: 0 20px 0 55px;
  }

  .svgFunnel {
    position: absolute;
    left: 16px;
    top: 11px;

  }
}