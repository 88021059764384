.row {
  height: 100vh;
  width: 100%;
  align-items: center;
  margin: 0 !important;

  .col {
    padding: 0;
    width: auto;
    margin: auto;

    .colDiv {
      max-width: 500px;
      display: flex;
      flex-direction: column;

      img {
        width: 308px;
        margin: 0 0 20px;
      }

      .signInForm {
        padding: 90px 0 15px;

        display: flex;
        flex-direction: column;
        gap: 25px;

        .signInWrapper{
          position: relative;

          display: flex;
          flex-direction: column;

          text-align: left;

          .error {
            position: absolute;
            top: -58px;
            width: 100%;
            padding: 8px;
          }
        }
      }

      .forgotPasswordLabel {
        padding: 15px 0;

        display: flex;
        justify-content: end;
      }

      .signUpWrapper {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;

        .svgWrapper {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }


    .shortFlixTheaterImage {
      width: 100%;
      height: 100vh;
    }
  }

  a:link {
    text-decoration: none;
  }
}
