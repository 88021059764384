

.header {
  height: 62px;
  background-color: #2a2a2a;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;

}

.body {
  //margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .approveImage {
    width: fit-content;
    margin: 0 auto;
  }

  .flex {
    max-width: 300px;
    display: flex;
    flex-direction: column;

    margin: 0 auto;

    .uploadImage {
      width: 200px;
      height: 200px;
      margin: 0 auto;

      border-radius: 18px;
    }
  }



  textarea {
    margin: 24px auto 0;
    width: 573px;
    height: 85px;

    padding: 11px 19px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1
  }

  textarea:focus, input:focus{
    outline: none;
  }
}

.footer {
  width: 100%;
  padding: 30px 0 36px 0;

  display: flex;
  justify-content: center;
  gap: 40px;

  .approve {
    width: 170px;
    height: 49px;
    background: #02cb60 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
  }

  .decline {
    width: 170px;
    height: 49px;
    background: #ff0000 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
  }

  .cancel {
    width: 170px;
    height: 49px;
    /* UI Properties */
    background: #707070 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
  }
}
