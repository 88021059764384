.contentContainer {
  padding: 25.5px 0px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  opacity: 1;

  .contentContainer__top {
    padding: 0 32px 20px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selectSection {
      display: flex;
      gap: 30px;
    }
  }
}

.cellWrapper {
  position: relative;

  display: flex;
  align-items: center;
  height: 100%;

  img {
    width: 120px;
    height: 73px;
    background: #1A1A1A 0% 0% no-repeat / center cover;
    border-radius: 20px;
    opacity: 1;
  }

  .svgPlay {
    position: absolute;
    margin: 0 auto;
    left: 50px;
    width: 15px;
    height: 17px;
  }
}

.descriptionWidth {
  //min-width: 300px;
  //max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
