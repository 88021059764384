

  .newFaqContainer__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 30px;

    .goBack {
      display: flex;
      align-items: center;
      gap: 20px;

      cursor: pointer;
    }

  }

  .formWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;

    .formContainer {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 20px;

      .input {
        max-width: 654px;
      }

      textarea {
        width: 100%;
        height: 125px;
        padding: 15px 25px;


        /* UI Properties */
        border: none;
        background: #EEEEEE 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
      }

      textarea:focus {
        outline: none;
      }

      .videoWrapper {
        height: 100%;
        width: 500px;
        max-width: 500px;

        margin: 0 auto;
        padding: 20px 0;
        border: 2px dashed #eee;

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .svgClapperBoard {
          margin: 0 auto;
          width: 100px;
          height: 100px;
        }

        .videoFields {
          padding-bottom: 20px;
        }

        .customFileUpload {
          border: 1px solid #ccc;
          display: inline-block;
          padding: 6px 12px;
          cursor: pointer;

          border-radius: 5px;
        }

        .customFileUpload:hover {
          background-color: #f0f0f0;
        }

        .customFileUpload input[type="file"] {
          display: none !important;
        }


      }

    }

    .btnWrapper {
      margin-top: 20px;

      display: flex;
      align-items: center;
      justify-content: end;
      flex-wrap: wrap;
      flex-direction: row;

      gap: 20px;
    }
  }

