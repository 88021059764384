.profileContainer {
  margin: 0 auto;
  max-width: 1641px;
  position: relative;

  .banner {
    width: 100%;
    height: 333px;
    border-radius: 15px;
  }

  .wrapper {
    position: relative;

    bottom: 100px;
    z-index: 10;

    .cardDetails {
      position: relative;
      margin: 0 80px;
      padding: 20px 13px;

      z-index: 10;

      width: auto;


      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 15px;

      opacity: 1;

      .cardDetailsFlex {
        position: relative;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: start;
        //justify-content: space-evenly;
        gap: 10px;

        .imageWrapper {
          @media screen and (min-width: 1280px) {
            min-width: 295px;
          }

          @media screen and (min-width: 1440px) {
            min-width: 295px;
          }

          @media screen and (min-width: 1680px) {
            min-width: 295px;
          }

          @media screen and (min-width: 1920px) {
            min-width: 295px;
          }
        }

        .groupFlex {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0 10px;

          @media screen and (min-width: 1280px) {
            display: flex;
            flex-direction: column;
          }

          @media screen and (min-width: 1440px) {
            display: flex;
            flex-direction: column;
          }

          @media screen and (min-width: 1680px) {
            display: flex;
            flex-direction: column;
          }

          @media screen and (min-width: 1920px) {
            display: flex;
            flex-direction: column;
          }
        }

      }
    }

    .selfieId {

      //display: none;
      position: absolute;
      left: 42px;
      top: -66px;
      z-index: 20;

      width: 160px;
      height: 156px;

      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 30px;

      background-size: auto;

      @media screen and (min-width: 1280px) {
        width: 160px;
        height: 156px;
      }

      @media screen and (min-width: 1440px) {
        width: 160px;
        height: 156px;
      }

      @media screen and (min-width: 1680px) {
        width: 190px;
        height: 186px;
      }

      @media screen and (min-width: 1920px) {
        width: 190px;
        height: 186px;
      }
    }

    .payoutHistory {
      padding: 0 30px 30px;
      /* UI Properties */
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 15px;

      overflow-y: scroll;



      .top {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #FFFFFF;
        padding: 25px 0 25px;

        display: flex;
        align-items: center;
        justify-content: space-between;


      }

      .history__details {
        display: flex;
        flex-direction: column;
        gap: 27px;


      }

      .history {
        display: flex;
        align-items: center;
        gap: 21px;

        .history__date {
          display: flex;
          flex-direction: column;
          gap: 4px;

        }
      }
    }

    .payoutHistory::-webkit-scrollbar {
      width: 0 !important;
      background: transparent;
    }


    .filterContent {
      width: 100%;
      height: 81px;
      padding: 33px;


      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;


      /* UI Properties */
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 15px;
    }



    .contentSection {
      margin: 40px 80px;
      height: 100%;

      .cardFeed {

        padding: 33px;
        margin-bottom: 30px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;


        .cardFeed_selfieId {
          width: 73px;
          height: 73px;

          background: transparent  0% 0% no-repeat padding-box;
          border-radius: 20px;
          background-size: auto;
        }

        .feedWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;

          .topDetails {
            display: flex;
            align-items: center;
            gap: 20px;
          }
        }

        .videoSection {
          //width: 100%;
          margin-bottom: 22px;

          .thumbnail {
            width: 100%;
            height: 480px;
            background-size: cover;
          }
        }

        .titleSection {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 20px;

          .title {
            max-height: 125px;
            overflow:hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
          }
        }

        .descriptionSection {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .description {
            max-height: 125px;
            overflow:hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
          }
        }

        .durationSection {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .loader {
        width: 100px;
      }

    }
  }

  .labelWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
  }

  .svgDetails {
    width: 19px;
    height: 19px;
  }

  .svgFunnel {
    width: 23px;
    height: 23px;

    cursor: pointer;
  }
}
