.tableContainer {
  width: 100%;
  height: 100%;

  overflow-x: auto;

  .loadingTr {
    height: 70px;

    border: none;
    border-color: transparent;
    position: relative;
  }

  .read {
    background: inherit;
  }
  .unRead {
    background: #f7d1d5;
  }
}

.pagination {
  width: 100%;
  padding: 0 32px;
  display: flex;
  //flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .paginationWrapper {
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 5px;

    .pageButton {
      padding: 0 15px;
      height: 59px;
      /* UI Properties */
      background: #eeeeee 0% 0% no-repeat padding-box;
      border-radius: 5px;
    }
  }
}

.svgDualRing {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
}

.labelNoData {
  width: 100px;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
}
