// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../../../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here

@font-face {
  font-family: 'Dunbar';
  font-weight: 400;
  src: url('../fonts/DunbarText-Regular.woff');
}

@font-face {
  font-family: 'Dunbar';
  font-weight: 600;
  src: url('../fonts/DunbarText-Medium.woff');
}

@font-face {
  font-family: 'Dunbar';
  font-weight: 800;
  src: url('../fonts/DunbarText-Bold.woff');
}

* {
  font-family: 'Dunbar', sans-serif;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1441px,
  xxxl: 1980px,
);
