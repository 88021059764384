.approvalDetailsContainer {
  /* UI Properties */
  padding: 38px 33px 63px 33px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  gap: 36px;

  .detailsTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .videoDetails {
    display: flex;
    flex-direction: row;
    gap: 40px;

    .profileImage {
      width: 47px;
      height: 47px;
      border-radius: 8px;
      margin-right: 10px;

      background: transparent 0% 0% no-repeat padding-box;
    }

    .selfieId {
      width: 200px;
      height: 200px;

      display: flex;
      justify-content: center;

      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 30px;

      background-size: auto;

      @media screen and (min-width: 1280px) {
        width: 200px;
        height: 200px;
      }

      @media screen and (min-width: 1440px) {
        width: 200px;
        height: 200px;
      }

      @media screen and (min-width: 1680px) {
        width: 300px;
        height: 300px;
      }

      @media screen and (min-width: 1920px) {
        width: 300px;
        height: 300px;
      }

    }

    .descriptionWrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .subDescription {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 40px;

        .subDiv {
          display: flex;
          flex-direction: column;
          gap: 10px;

          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .btnSection {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: end;
        gap: 30px;
      }
    }
  }

  .goBack {
    display: flex;
    align-items: center;
    gap: 20px;

    cursor: pointer;
  }

  .labelWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
  }

  .svgDetails {
    width: 19px;
    height: 19px;
  }
}
