.row {
  height: 100vh;
  width: 100%;
  align-items: center;
  margin: 0 !important;

  .col {
    padding: 0;
    width: auto;
    margin: auto;

    .colDiv {
      max-width: 500px;
      display: flex;
      flex-direction: column;

      img {
        width: 308px;
        margin: 0 0 20px;
      }

      .resetPasswordForm {
        padding: 87px 0 40px;

        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }

    .shortFlixTheaterImage {
      width: 100%;
      height: 100vh;
    }

  }
}
