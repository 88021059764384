.layoutNavbar {
  z-index: 1000;
  position: sticky;
  top: 0;

  width: 100%;
  height: 110px;

  background: #1A1A1A 0% 0% no-repeat padding-box;
  color: #FFFFFF;

  display: flex;
  align-items: center;

  .layoutWrapper {
    width: 100%;
    padding: 0 35px;

    display: flex;
    align-items: center;

    .layoutDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      padding-left: 62px;

      .welcomeDiv {
        display: flex;
        align-items: center;
        gap: 50px;
      }

      .logoutWrapper {
        display: flex;
        align-items: center;
        gap: 30px;

        .logoutDiv {
          position: relative;
          display: flex;
          align-items: center;
          gap: 18px;
        }

        .svgHamburgerMenu {
          background-color: #D4D4D4;
          width: 54px;
          height: 54px;

          border-radius: 10px;
        }

        .svgLogout {
          cursor: pointer;

        }
      }
    }
  }
}