.detailsContainer {
  position: relative;
  height: 686px;
  /* UI Properties */
  padding: 38px 33px 63px 33px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  gap: 36px;

  .detailsTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .videoDetails {
    display: flex;
    flex-direction: row;
    gap: 40px;

    .profileImage {
      width: 47px;
      height: 47px;
      border-radius: 8px;
      margin-right: 10px;

      background: transparent 0% 0% no-repeat padding-box;
    }

    .descriptionWrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .subDescription {
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .subDiv {
          margin-bottom: 20px;
          display: flex;

          flex-direction: column;
          gap: 10px;

          overflow: hidden;
          text-overflow: ellipsis;
        }

      }

      .btnSection {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: end;
        gap: 30px;
      }
    }
  }

  .goBack {
    display: flex;
    align-items: center;
    gap: 20px;

    cursor: pointer;
  }

  .svgDualRing {
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
  }
}
