.layoutMenu {
  margin-top: 110px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  //height: 100%;
  //min-height: 100vh;
  margin-right: 0;
  margin-left: 0;

  z-index: 999;
  width: 110px;
  background: #ffffff 0% 0% no-repeat padding-box;

  .innerBorder {
    position: relative;
    height: 100%;

    padding: 0 6px;

    display: flex;
    flex-direction: column;
    text-align: center;
    overflow-y: scroll;
  }

  .innerBorder::-webkit-scrollbar {
    width: 0 !important;
    background: transparent;
  }

  .menuItem {
    padding: 23px 0;

    .iconWrapper {
      position: relative;
      padding: 0 0 10px 0;
    }

    .badge {
      position: absolute;
      top: -10px;
      right: 13px;
    }
  }
  a {
    text-decoration: none;
  }
}
