.approvalsContainer {
  padding: 25.5px 0px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  opacity: 1;

  .approvalsContainer__top {
    padding: 0 32px 20px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selectSection {
      display: flex;
      gap: 30px;
    }
  }
}

.imageWrapper {
  width: 120px;
  height: 70px;
  border-radius: 20px;
}
.cellWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.descriptionWidth {
  min-width: 300px;
  max-width: 300px;
}
