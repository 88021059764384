.grid__container {
  display: grid;
  grid-template-columns: 2fr 386px; /* set the width of the columns */
  grid-template-rows: 626px;
  grid-gap: 40px 30px;

  @media screen and (max-width: 768px) {
    .grid__container {
      grid-template-rows: 150px 250px; /* set the specific heights for the rows for smaller screens */
    }
  }

  .dashboardCard {
    height: auto;
    width: 100%;
    padding: 26px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
  }

  .historyCard {
    position: relative;
    padding: 0 26px 26px 26px;

    height: 100%;
    width: 100%;

    margin-bottom: 30px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;

    overflow-y: auto;

    .topTitleFlex {
      position: sticky;
      top: 0;
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      background: #ffffff;

      z-index: 1;
    }
    .flexWrapper {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      padding: 20px 0;
      border-bottom: 1px solid #00000029;
      cursor: pointer;

      .flex {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;

        gap: 18px;

        .image {
          width: 60px;
          height: 45px;

          border-radius: 8px;
        }
      }
    }

    button {
      width: 84px;
      height: 35px;
      /* UI Properties */
      background: #eeeeee 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;

      border: none;
    }

    .br {
      color: #2a2a2a;
      background-color: #00b6e9;
    }
  }

  .historyCard::-webkit-scrollbar {
    width: 0 !important;
    background: transparent;
  }
}
