.notFoundPageContainer {
  width: 100%;
  height: 100vh;

  h1 {
    font-size: 100px;
  }

  .wrapper {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
}