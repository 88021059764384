.appAnalysisContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .activeUserCard {
    padding: 26px;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
  }

  .approvalStaticCard {
    padding: 26px;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
  }

  .topCreatorsContainer {
    padding: 25.5px;

    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;

    .label {
      margin: 5px 0 20px;
    }
  }

  .cellWrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .imageWrapper {
    width: 120px;
    height: 70px;
    border-radius: 20px;
  }
}
