.dropDownContainer {
  //height: 100%;
  position: relative;
  background-color: transparent;

  .optionContainer {
    position: absolute;
    right: 0;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;


    .optionWrapper {
      width: 130px;
      height: 100%;
      background-color: white;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }
  }

  .svgFunnel {
    width: 23px;
    height: 23px;

    cursor: pointer;
  }
}