.row {
  gap: 20px 0;

  .cardDiv {
    height: 200px;
    width: 100%;

    @media screen and (min-width: 1440px) {
      height: 200px;
    }

    @media screen and (min-width: 1920px) {
      height: 240px;
    }

    position: relative;

    .cardRow {
      position: relative;
      padding: 0;
      display: flex;
      flex-direction: row;

      height: 100%;
      width: 100%;
      margin: 0;

      .col1 {
        position: relative;
        width: 60%;
        padding: 20px 15px;

        display: flex;
        gap: 16px;

        @media screen and (min-width: 1440px) {
          padding: 20px 15px;
          gap: 16px;
        }

        @media screen and (min-width: 1680px) {
          padding: 20px 15px;
          gap: 20px;
        }

        @media screen and (min-width: 1920px) {
          padding: 40px 30px;
          gap: 25px;
        }

        .selfieId {
          min-width: 90px;
          height: 90px;

          box-shadow: 0px 3px 6px #00000029;
          border-radius: 8px;

          background-repeat: no-repeat;
          background-size: auto;

          @media screen and (min-width: 1280px) {
            min-width: 90px;
            height: 90px;
          }
          @media screen and (min-width: 1440px) {
            min-width: 90px;
            height: 90px;
          }
          @media screen and (min-width: 1920px) {
            min-width: 160px;
            height: 160px;
          }
        }

        .detailsWrapper {
          height: 100%;
          width: 100%;

          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .badgeWrapper {
            margin-top: 32px;
            height: 100%;
            display: flex;
            align-items: center;

            gap: 30px;

          }
        }
      }

      .col2 {
        background-color: #d1002a;
        height: 100%;
        width: 109px;

        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        cursor: pointer;
        z-index: 1;

        .svgEye {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .svgTrashBin {
    width: 20px;
    height: 20px;
    color: #ca2d2d;

    cursor: pointer;

  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;

    height: 240px;
    width: 109px;
    background-color: #d1002a;
  }
}

.creatorSearch {
  display: flex;
  justify-content: end;

  margin-bottom: 30px;
}
.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 120px;
  height: 120px;
}

.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.gridItem {
  background-color: #ccc;
  padding: 20px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .gridContainer {
    grid-template-rows: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-flow: dense;
  }
}

.pagination {
  margin: 30px 0;
  width: 100%;

  .paginationWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 5px;

    .pageButton {
      min-width: 60px;
      height: 59px;
      padding: 0 15px;

      /* UI Properties */
      border: none;
      background: #f4f4f4 0% 0% no-repeat padding-box;
      border-radius: 5px;



      &:hover {
        -webkit-box-shadow: 3px 3px 10px 6px #dddddd;
        -moz-box-shadow: 3px 3px 10px 6px #dddddd;
        box-shadow: 3px 3px 10px 6px #dddddd;
      }

      &:disabled {
        background: #999;
        color: #555;
        cursor: not-allowed !important;
      }
    }
  }
}
